import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConstantService {
  constructor() {}
  public constant = {
    responseOption: [
      {
        name: "Not rated",
      },
      {
        name: "Present",
      },
      {
        name: "Not present",
      },
    ],
    needMapping: [
      {
        name: "Housing Insecurity",
        mapWith: "Housing",
        id: "1",
        surveyResponse:
          "I have housing today but am worried about losing it in the future",
        status:'active'
      },
      {
        name: "Financial Difficulties",
        mapWith: "Money",
        id: "2",
        surveyResponse: "Somewhat difficult",
        status:'active'
      },
      {
        name: "Food Insecurity",
        mapWith: "Food",
        id: "3",
        surveyResponse: "No",
        status:'active'
      },
      {
        name: "Utility Insecurity",
        mapWith: "Utilities",
        id: "4",
        surveyResponse: "No",
        status:'active'
      },
      {
        name: "Med Transportation",
        mapWith: "Transportation",
        id: "5",
        surveyResponse: "Yes",
        status:'active'
      },
      {
        name: "Social isolation/loneliness",
        mapWith: "Companionship",
        id: "6",
        surveyResponse: "Some of the time",
        status:'active'
      },
      {
        name: "Abuse",
        mapWith: "Abuse",
        id: "7",
        surveyResponse: "Rarely",
        status:'active'
      },
      {
        name: "Depression",
        mapWith: "Depression",
        id: "8",
        surveyResponse: "Several days",
        status:'active'
      },
      {
        name: "Staying safe during COVID",
        mapWith: "COVID",
        id: "9",
        surveyResponse: "Somewhat confident",
        status:'active'
      },
      {
        name: "Problems Managing Health",
        mapWith: "Chronic",
        id: "10",
        surveyResponse: "No, I am having trouble managing my health conditions",
        status:'active'
      },
      {
        name: "Digital Divide",
        mapWith: "DigitalDivide",
        id: "11",
        surveyResponse: "Not at all",
        status:'active'
      },
      {
        name: "Other",
        mapWith: "other1",
        id: "12",
        surveyResponse: "",
        status:'active'
      },
      {
        name: "Other",
        mapWith: "other2",
        id: "13",
        surveyResponse: "",
        status:'active'
      },
      {
        name: "Other",
        mapWith: "other3",
        id: "14",
        surveyResponse: "",
        status:'active'
      },
    ],
    statusOption: [
      {
        name: "Not started",
        color: "",
      },
      {
        name: "In progress",
        color: "bg-warning-o-30",
      },
      {
        name: "Referral provided",
        color: "bg-success-o-30",
      },
      {
        name: "Service received",
        color: "bg-success-o-30",
      },
      {
        name: "Failed",
        color: "bg-danger-o-30",
      },
      {
        name: "NA",
        color: "",
      },
    ],
    overallStatusOption: [
      {
        name: "Active",
      },
      {
        name: "Not started",
      },
      {
        name: "Lost to follow-up",
      },
      {
        name: "Complete",
      },
      {
        name: "NA",
      },
    ],
  };
}
