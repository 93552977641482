import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";

 
interface response {
  statusCode: any;
  data: any;
  error: any;
}

@Injectable({
  providedIn: "root",
})
export class PatientService {
  responseData: any;
  constructor(private http: HttpClient, private router: Router) {}
  
  getSurvey(id): Observable<any> {
    this.responseData = this.http.get<response>(
      environment.apiEndpoint + "survey?id=" + id
    );
    return this.responseData;
  }

  getNotes(id): Observable<any> {
    this.responseData = this.http.get<response>(
      environment.apiEndpoint + "note?id=" + id
    );
    return this.responseData;
  }

  savePatient(data): Observable<any> {
    this.responseData = this.http.post<response>(
      environment.apiEndpoint + "patient",
      data
    );
    return this.responseData;
  }

  getPatientImport(data): Observable<any> {
    this.responseData = this.http.post<response>(
      environment.apiEndpoint + "import/get",data
    );
    return this.responseData;
  }

  getAllPatient(data): Observable<any> {
    this.responseData = this.http.post<response>(
      environment.apiEndpoint + "patient/get",data
    );
    return this.responseData;
  }

  getPatientById(id): Observable<any> {
    this.responseData = this.http.get<response>(
      environment.apiEndpoint + "patient/" + id
    );
    return this.responseData;
  }

  updatePatient(id, data): Observable<any> {
    this.responseData = this.http.put<response>(
      environment.apiEndpoint + "patient/" + id,
      data
    );
    return this.responseData;
  }

  makeid() {
    let result =
      Math.random().toString(36).substring(7) +
      "" +
      Math.random().toString(36).substring(7);
    return result;
  }

  getIndex(array, key, val) {
    let dataIndex = array.findIndex((x) => x[key] == val);
    return dataIndex;
  }

  updateNote(id, data): Observable<any> {
    this.responseData = this.http.put<response>(
      environment.apiEndpoint + "note/" + id,
      data
    );
    return this.responseData;
  }

  saveNote(data): Observable<any> {
    this.responseData = this.http.post<response>(
      environment.apiEndpoint + "note",
      data
    );
    return this.responseData;
  }
  saveSurvey(data): Observable<any> {
    this.responseData = this.http.post<response>(
      environment.apiEndpoint + "survey",
      data
    );
    return this.responseData;
  }

  updateSurvey(id, data): Observable<any> {
    this.responseData = this.http.put<response>(
      environment.apiEndpoint + "survey/" + id,
      data
    );
    return this.responseData;
  }
}
