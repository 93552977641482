<body id="kt_body" class="header-fixed header-mobile-fixed subheader-enabled">
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
      <div
        class="d-flex flex-column flex-row-fluid wrapper pl-0"
        id="kt_wrapper"
      >
        <div
          id="kt_header"
          class="header bg-white header-fixed"
          kt-hidden-height="80"
          style="left: 0 !important"
        >
          <!--begin::Container-->
          <div
            class="container-fluid d-flex align-items-stretch justify-content-between"
          >
            <!--begin::Left-->
            <div class="d-flex align-items-stretch mr-2">
              <!--begin::Page Title-->
              <h3
                class="d-none text-dark d-lg-flex align-items-center mr-10 mb-0"
              >
                SDOH Manager
              </h3>
              <!--end::Page Title-->
              <!--begin::Header Menu Wrapper-->
              <div
                class="header-menu-wrapper header-menu-wrapper-left"
                id="kt_header_menu_wrapper"
              >
                <!--begin::Header Menu-->
                <div
                  id="kt_header_menu"
                  class="header-menu header-menu-mobile header-menu-layout-default"
                >
                  <!--begin::Header Nav-->
                  <ul class="menu-nav">
                    <li class="menu-item" *ngIf ="userType == 'user'">
                      <a
                        href="javascript:void(0)"
                        (click)="goToPatient()"
                        class="menu-link"
                      >
                        <span class="menu-text">Patients</span>
                      </a>
                    </li>
                    <li class="menu-item" *ngIf ="userType == 'admin'">
                      <a
                        href="javascript:void(0)"
                        (click)="goToUser()"
                        class="menu-link"
                      >
                        <span class="menu-text">Users</span>
                      </a>
                    </li>
                  </ul>
                  <!--end::Header Nav-->
                </div>
                <!--end::Header Menu-->
              </div>
              <!--end::Header Menu Wrapper-->
            </div>
            <!--end::Left-->
            <!--begin::Topbar-->
            <div class="topbar">
              <!--begin::User-->
              <div class="topbar-item">
                <div
                  class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                  id="kt_quick_user_toggle" (click) = "logout()"
                >
                  <div class="d-flex flex-column text-right pr-3">
                    <span
                      class="text-muted font-weight-bold font-size-base d-none d-md-inline"
                      >{{userName}}</span
                    >
                    <!-- <span
                      class="text-dark-75 font-weight-bolder font-size-base d-none d-md-inline"
                      >User</span
                    > -->
                  </div>
                  <!-- <span class="symbol symbol-35 symbol-light-primary">
                    <span class="symbol-label font-size-h5 font-weight-bold"
                      >D</span
                    >
                   
                  </span> -->
                  <span class="symbol symbol-35 symbol-light-primary">
                    <span class="symbol-label font-size-h5 font-weight-bold"
                    ><i class="fa fa-sign-out text-primary" aria-hidden="true"></i></span
                  >
                  </span>
                </div>
              </div>
              <!--end::User-->
            </div>
            <!--end::Topbar-->
          </div>
          <!--end::Container-->
        </div>

        <div
          class="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div class="d-flex flex-column-fluid">
            <div class="container mx-10 p-0" style="max-width: 100% !important">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
