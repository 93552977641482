import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
}) 
export class MessageService {
  constructor() {}
  public messages = {
    patDemoSave: "Patient Detail Saved",
    patDemoUpdate: "Patient Detail updated",
    noteUpdate: "Note Updated",
    surveyUpdate: "Survey updated",
    someIssue: "Something went wrong. Please try again later",
    userDeleted: "User deleted successfully",
    logIn: "User successfully logged In",
    logOut: "User logged out",
    userSaved: "User saved successfully",
    userUpdated: "User updated successfully",
    authFailed: "Authorization failed",
    userExist:"User with this email already exists",
    pageAccess:"Access Denied",
    emptyResp:"Please add response",
    emptyNeed:"Need should not be empty"
  };
}
