import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BaseComponent } from "./shared/component/base/base.component";
import { ToastrModule } from "ngx-toastr";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormatterService } from "./shared/service/dateFormatter/formatter.service";
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "./shared/service/auth/auth.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CustomInterceptor } from "./shared/service/requestInterceptor/custom.interceptor";
import { UserService } from "./user/user.service";
import { PatientService } from "./patient/patient.service";

@NgModule({
  declarations: [AppComponent, BaseComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      positionClass: "toast-top-center",
      closeButton: true,
    }), // ToastrModule added
  ],
  providers: [
    AuthService,
    UserService,
    PatientService,
    { provide: NgbDateParserFormatter, useClass: FormatterService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
