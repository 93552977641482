<div class="row mt-10" *ngIf="mode == 'new' && showSpinner == false">
    <div class="col-xl-12">
        <div class="card card-custom gutter-b card-stretch" id="">
            <div class="card-header border-0 py-5 px-5">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label font-weight-bolder pb-2 opacity-70">Patient Details</span
          >
        </h3> 
      </div> 
      <div class="card-body pt-0 px-5">
        <div class="card-scroll" style="overflow-x: hidden;">
          <form
            class="form"
            [formGroup]="patDemoForm"
            autocomplete="off"
            novalidate="novalidate"
          >
            <div class="form-group row">
              <div class="col-lg-4">
                <label>Tags</label>
                <div class="checkbox-inline">
                  <label class="checkbox checkbox-primary">
                    <input
                      type="checkbox"
                      name="tag1"
                      [checked]="teamReview"
                      (change)="onCheckChange($event, 'teamReview')"
                    />
                    <span></span>Team Review</label>
                    <label class="checkbox checkbox-primary">
                    <input
                      type="checkbox"
                      name="tag2"
                      [checked]="urgentIssue"
                      (change)="onCheckChange($event, 'urgentIssue')"
                    />
                    <span></span>Urgent Issue</label
                  >
                </div>
              </div>
              <div class="col-lg-4">
                <label>Overall Status</label>
                    <select class="form-control" id="exampleSelect1" name="overallStatus" [formControl]="patDemoForm.controls['overallStatus']">
                  <option
                    *ngFor="let item of overallStatusOption"
                    value="{{ item.name }}"
                  >
                    {{ item.name }}
                  </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-lg-4">
                <label>First Name</label>
                <input type="text" class="form-control" name="fname" [formControl]="patDemoForm.controls['fname']" placeholder="Enter First name" />
                <span class="form-text text-danger" *ngIf="
                    patDemoForm.controls['fname'].hasError('required') &&
                    patDemoForm.controls['fname'].touched
                  ">Please enter first name</span
                >
              </div>
              <div class="col-lg-4">
                <label>Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="lname"
                  placeholder="Enter Last Name"
                  [formControl]="patDemoForm.controls['lname']"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    patDemoForm.controls['lname'].hasError('required') &&
                    patDemoForm.controls['lname'].touched
                  "
                  >Please enter last name</span
                >
              </div>
              <div class="col-lg-4">
                <label>DOB</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    name="dob"
                    [formControl]="patDemoForm.controls['dob']"
                    placeholder="Select date"
                    ngbDatepicker
                    #d="ngbDatepicker"
                    (click)="d.toggle()"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fa fa-calendar" (click)="d.toggle()"></i>
                    </span>
            </div>
        </div>
        <span class="form-text text-danger" *ngIf="
                    patDemoForm.controls['dob'].hasError('required') &&
                    patDemoForm.controls['dob'].touched
                  ">DOB is required</span
                >
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label>Gender</label>
                <div class="radio-inline">
                  <label class="radio radio-solid">
                    <input
                      type="radio"
                      name="gender"
                      [formControl]="patDemoForm.controls['gender']"
                      value="male"
                    />
                    <span></span>Male</label>
        <label class="radio radio-solid">
                    <input
                      type="radio"
                      name="gender"
                      [formControl]="patDemoForm.controls['gender']"
                      value="female"
                    />
                    <span></span>Female</label
                  >
                </div>
                <span
                  class="form-text text-danger"
                  *ngIf="
                    patDemoForm.controls['gender'].hasError('required') &&
                    patDemoForm.controls['gender'].touched
                  "
                  >Please select gender</span
                >
              </div>
              <div class="col-lg-4">
                <label>Home Phone</label>
        <input type="number" class="form-control" name="phone" [formControl]="patDemoForm.controls['phone']" placeholder="Enter Phone" />
        <span class="form-text text-danger" *ngIf="
                    patDemoForm.controls['phone'].hasError('required') &&
                    patDemoForm.controls['phone'].touched
                  ">This field is required</span
                >
              </div>
              <div class="col-lg-4">
                <label>Mobile Phone</label>
                <input
                  type="number"
                  class="form-control"
                  name="sms"
                  [formControl]="patDemoForm.controls['sms']"
                  placeholder="Enter Phone No."
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label>Language</label>
                <input
                  type="text"
                  class="form-control"
                  name="language"
                  [formControl]="patDemoForm.controls['language']"
                  placeholder="Enter Language"
                />
              </div>
              <div class="col-lg-4">
                <label>CHA Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="chaName"
                  [formControl]="patDemoForm.controls['chaName']"
                  placeholder=""
                />
              </div>
              <div class="col-lg-4">
                <label>Insurance & Other Services</label>
                <textarea
                  type="text"
                  class="form-control"
                  name="insuranceAndOtherServices"
                  [formControl]="
                    patDemoForm.controls['insuranceAndOtherServices']
                  "
                  placeholder="Insurance & Other Services ..."
                  row="3"
                ></textarea>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label>Primary Care Practice Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="pracName"
                  [formControl]="
                    patDemoForm.controls['practice'].controls['name']
                  "
                  placeholder="Enter Practice Name"
                />
              </div>
              <div class="col-lg-4">
                <label>Practice Phone</label>
                <input
                  type="number"
                  class="form-control"
                  name="pracName"
                  [formControl]="
                    patDemoForm.controls['practice'].controls['phone']
                  "
                  placeholder="Enter Practice Phone"
                />
              </div>
              <div class="col-lg-4">
                <label>Practice Email</label>
                <input
                  type="email"
                  class="form-control"
                  name="pracEmail"
                  [formControl]="
                    patDemoForm.controls['practice'].controls['email']
                  "
                  placeholder="Enter Practice Email"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    patDemoForm.controls['practice'].controls['email'].hasError(
                      'email'
                    ) &&
                    patDemoForm.controls['practice'].controls['email'].touched
                  "
                  >Enter valid Email</span
                >
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label>Primary Care Provider Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="providerName"
                  [formControl]="
                    patDemoForm.controls['provider'].controls['name']
                  "
                  placeholder="Enter Practice Provider"
                />
              </div>
              <div class="col-lg-4">
                <label>Provider Phone</label>
                <input
                  type="number"
                  class="form-control"
                  name="providerPhone"
                  [formControl]="
                    patDemoForm.controls['provider'].controls['phone']
                  "
                  placeholder="Enter Provider Phone"
                />
              </div>
              <div class="col-lg-4">
                <label>Provider Email</label>
                <input
                  type="email"
                  class="form-control"
                  name="providerEmail"
                  [formControl]="
                    patDemoForm.controls['provider'].controls['email']
                  "
                  placeholder="Enter Provider Email"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    patDemoForm.controls['provider'].controls['email'].hasError(
                      'email'
                    ) &&
                    patDemoForm.controls['provider'].controls['email'].touched
                  "
                  >Enter valid Email</span
                >
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label>Practice Key Contact</label>
                <input
                  type="text"
                  class="form-control"
                  name="practiceKeyContactName"
                  [formControl]="
                    patDemoForm.controls['practiceKeyContact'].controls[
                      'contact'
                    ]
                  "
                  placeholder="Enter Practice Contact"
                />
              </div>
              <div class="col-lg-4">
                <label>Key Contact Phone</label>
                <input
                  type="number"
                  class="form-control"
                  name="practiceKeyPhone"
                  [formControl]="
                    patDemoForm.controls['practiceKeyContact'].controls['phone']
                  "
                  placeholder="Key Contact Phone"
                />
              </div>
              <div class="col-lg-4">
                <label>Key Contact Email</label>
                <input
                  type="email"
                  class="form-control"
                  name="practiceKeyEmail"
                  [formControl]="
                    patDemoForm.controls['practiceKeyContact'].controls['email']
                  "
                  placeholder="Key Contact Email"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    patDemoForm.controls['practiceKeyContact'].controls[
                      'email'
                    ].hasError('email') &&
                    patDemoForm.controls['practiceKeyContact'].controls['email']
                      .touched
                  "
                  >Enter valid Email</span
                >
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label>Caregiver Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="caregiverName"
                  [formControl]="
                    patDemoForm.controls['caregiver'].controls['name']
                  "
                  placeholder="Enter Caregiver Name"
                />
              </div>
              <div class="col-lg-4">
                <label>Caregiver Phone</label>
                <input
                  type="number"
                  class="form-control"
                  name="caregiverPhone"
                  [formControl]="
                    patDemoForm.controls['caregiver'].controls['phone']
                  "
                  placeholder="Caregiver Phone"
                />
              </div>
              <div class="col-lg-4">
                <label>MRN At PCP</label>
                <input
                  type="number"
                  class="form-control"
                  name="mrn"
                  [formControl]="patDemoForm.controls['mrn']"
                  placeholder="MRN"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label>Which one of these are you?</label>
                <div class="radio-list">
                  <label class="radio radio-solid">
                    <input
                      type="radio"
                      name="nonPatient"
                      [formControl]="patDemoForm.controls['nonPatient']"
                      value="patient"
                    />
                    <span></span>I am a PATIENT at one of these clinics</label>
        <label class="radio radio-solid">
                    <input
                      type="radio"
                      name="nonPatient"
                      [formControl]="patDemoForm.controls['nonPatient']"
                      value="caregiver"
                    />
                    <span></span>I am a CAREGIVER for a patient from one of these clinics</label
                  >

                  <label class="radio radio-solid">
                    <input
                      type="radio"
                      name="nonPatient"
                      [formControl]="patDemoForm.controls['nonPatient']"
                      value="not patient/caregiver"
                    />
                    <span></span>I am NOT a patient/caregiver for a patient from one of these clinics</label
                  >
                </div>


              </div>
              <div class="col-lg-4">
                <label>Outreach Ok?</label>
        <div class="radio-inline">
            <label class="radio radio-solid">
                    <input
                      type="radio"
                      name="outReach"
                      [formControl]="patDemoForm.controls['outReach']"
                      value="yes"
                    />
                    <span></span>Yes</label
                  >
                  <label class="radio radio-solid">
                    <input
                      type="radio"
                      name="outReach"
                      [formControl]="patDemoForm.controls['outReach']"
                      value="no"
                    />
                    <span></span>No</label
                  >
                </div>
              </div>
             
            </div>

            <div class="row">
              <div class="col-lg-12">
                <button
                  type="submit"
                  class="btn btn-primary mr-2 pull-right"
                  (click)="submitPatDemoForm()"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" style="height: 70vh" *ngIf="showSpinner == true">
  <div class="col-xl-12">
    <div
      style="height: 70vh"
      class="spinner spinner-track spinner-lg spinner-primary spinner-center"
    ></div>
  </div>
</div>

<div
  class="row pb-10 mt-10"
  *ngIf="mode == 'edit' && patientData && showSpinner == false"
>
  <div class="col-xl-12">
    <ngb-accordion #acc="ngbAccordion" activeIds="">
      <ngb-panel cardClass="card-custom" id="pat-demo-panel">
        <ng-template ngbPanelHeader>
          <h3
            class="card-title align-items-start flex-column mt-5"
            style="width: 100%"
          >
            <button
              ngbPanelToggle
              class="btn p-0 card-label font-weight-bolder mx-0"
              style="width: 100%"
            >
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column flex-root col-sm-4 text-left">
                <span class="font-weight-bolder mb-2 opacity-70">{{ patDemoForm.controls["fname"].value }}
                  {{ patDemoForm.controls["lname"].value }}</span>
              </div>
              <div class="d-flex flex-column flex-root col-sm-4 text-left">
                  <span class="font-weight-bolder font-size-sm opacity-70">Mobile Phone</span>
                  <span class="text-muted font-weight-bold font-size-sm">{{
                    patDemoForm.controls["sms"].value || 'NA'
                  }}</span>
              </div>
              <div class="d-flex flex-column flex-root col-sm-4 text-left">
                <span class="font-weight-bolder font-size-sm opacity-70">Home Phone</span>
                <span class="text-muted font-weight-bold font-size-sm">{{
                  patDemoForm.controls["phone"].value || 'NA'
                }}</span>
            </div>
              <div class="d-flex flex-column flex-root col-sm-4 text-left">
                  <span class="font-weight-bolder font-size-sm opacity-70">CHA Name</span>
                  <span class="text-muted font-weight-bold font-size-sm">{{
                    patDemoForm.controls["chaName"].value || 'NA'
                  }}</span>
              </div>
              <div class="d-flex flex-column flex-root col-sm-4 text-left">
                  <span class="font-weight-bolder font-size-sm opacity-70">Primary care Provider</span>
                  <span class="text-muted font-weight-bold font-size-sm">{{
                    patDemoForm.controls["provider"].value.name || 'NA'
                  }}</span>
              </div>

              <div class="d-flex flex-column flex-root col-sm-4 text-right">
                <span
                class="pull-right font-weight-bold font-size-lg text-dark-75  expand-accordian"
                >Expand<i
                  class="ml-1 fa fa-angle-down font-weight-bolder text-dark-75"
                  aria-hidden="true"
                ></i
              ></span>
              <span
                class="pull-right font-weight-bold font-size-lg text-dark-75  min-accordian"
                >Collapse<i
                  class="ml-1 fa fa-angle-up font-weight-bolder text-dark-75"
                  aria-hidden="true"
                ></i
              ></span>
              </div>
          </div>
            
              <!-- <span class="pull-left mr-30"
                >{{ patDemoForm.controls["fname"].value }}
                {{ patDemoForm.controls["lname"].value }}</span
              >

              <span
                class="font-weight-bolder font-size-lg pull-left mt-1 mr-30"
                *ngIf="patDemoForm.controls['phone'].value"
                >H:
                <span class="font-weight-bold font-size-sm">{{
                  patDemoForm.controls["phone"].value
                }}</span></span
              >

              <span
                class="font-weight-bolder font-size-lg pull-left mt-1 mr-30"
                *ngIf="patDemoForm.controls['sms'].value"
                >M:
                <span class="font-weight-bold font-size-sm">{{
                  patDemoForm.controls["sms"].value
                }}</span></span
              > -->

              <!-- <span
                class="pull-right font-weight-bold font-size-lg text-dark-75 bg-hover-light expand-accordian"
                >Expand<i
                  class="ml-1 fa fa-angle-down font-weight-bolder text-dark-75"
                  aria-hidden="true"
                ></i
              ></span>
              <span
                class="pull-right font-weight-bold font-size-lg text-dark-75 bg-hover-light min-accordian"
                >Collapse<i
                  class="ml-1 fa fa-angle-up font-weight-bolder text-dark-75"
                  aria-hidden="true"
                ></i
              ></span> -->
            </button>
          </h3>
          <div
            class="d-flex flex-wrap align-items-center px-2 py-4"
            style="width: 100%"
          >
            <div class="col-lg-4">
              <label>Tags</label>
            <div class="checkbox-inline">
                <label class="checkbox checkbox-primary">
                  <input
                    type="checkbox"
                    name="tag1"
                    [checked]="teamReview"
                    (change)="onCheckChange($event, 'teamReview')"
                  />
                  <span></span>Team Review</label
                >
                <label class="checkbox checkbox-primary">
                  <input
                    type="checkbox"
                    name="tag2"
                    [checked]="urgentIssue"
                    (change)="onCheckChange($event, 'urgentIssue')"
                  />
                  <span></span>Urgent Issue</label
                >
              </div>
            </div>
            <div class="col-lg-4">
              <label>Overall Status</label>
                <select class="form-control" id="exampleSelect1" name="overallStatus" [(ngModel)]="overAllStatusModel" (change)="setOverAllStatus($event.target.value)">
                <option
                  *ngFor="let item of overallStatusOption"
                  value="{{ item.name }}"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
        </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <form class="form" [formGroup]="patDemoForm" autocomplete="off" novalidate="novalidate">
                <!-- <div class="form-group row">
              <div class="col-lg-4">
                <label>Tags</label>
                <div class="checkbox-inline">
                  <label class="checkbox checkbox-primary">
                    <input
                      type="checkbox"
                      name="tag1"
                      [checked]="teamReview"
                      (change)="onCheckChange($event, 'teamReview')"
                    />
                    <span></span>Team Review</label
                  >
                  <label class="checkbox checkbox-primary">
                    <input
                      type="checkbox"
                      name="tag2"
                      [checked]="urgentIssue"
                      (change)="onCheckChange($event, 'urgentIssue')"
                    />
                    <span></span>Urgent Issue</label
                  >
                </div>
              </div>
              <div class="col-lg-4">
                <label>Overall Status</label>
                <select
                  class="form-control"
                  id="exampleSelect1"
                  name="overallStatus"
                  [formControl]="patDemoForm.controls['overallStatus']"
                >
                  <option
                    *ngFor="let item of overallStatusOption"
                    value="{{ item.name }}"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div> -->

                <div class="form-group row">
                    <div class="col-lg-4">
                        <label>First Name</label>
                        <input type="text" class="form-control" name="fname" [formControl]="patDemoForm.controls['fname']" placeholder="Enter First name" />
                        <span class="form-text text-danger" *ngIf="
                    patDemoForm.controls['fname'].hasError('required') &&
                    patDemoForm.controls['fname'].touched
                  ">Please enter first name</span
                >
              </div>
              <div class="col-lg-4">
                <label>Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="lname"
                  placeholder="Enter Last Name"
                  [formControl]="patDemoForm.controls['lname']"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    patDemoForm.controls['lname'].hasError('required') &&
                    patDemoForm.controls['lname'].touched
                  "
                  >Please enter last name</span
                >
              </div>
              <div class="col-lg-4">
                <label>DOB</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    name="dob"
                    [formControl]="patDemoForm.controls['dob']"
                    placeholder="Select date"
                    ngbDatepicker
                    #d="ngbDatepicker"
                    (click)="d.toggle()"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fa fa-calendar" (click)="d.toggle()"></i>
                    </span>
                    </div>
                </div>
                <span class="form-text text-danger" *ngIf="
                    patDemoForm.controls['dob'].hasError('required') &&
                    patDemoForm.controls['dob'].touched
                  ">DOB is required</span
                >
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label>Gender</label>
                <div class="radio-inline">
                  <label class="radio radio-solid">
                    <input
                      type="radio"
                      name="gender"
                      [formControl]="patDemoForm.controls['gender']"
                      value="male"
                    />
                    <span></span>Male</label>
                <label class="radio radio-solid">
                    <input
                      type="radio"
                      name="gender"
                      [formControl]="patDemoForm.controls['gender']"
                      value="female"
                    />
                    <span></span>Female</label
                  >
                </div>
                <span
                  class="form-text text-danger"
                  *ngIf="
                    patDemoForm.controls['gender'].hasError('required') &&
                    patDemoForm.controls['gender'].touched
                  "
                  >Please select gender</span
                >
              </div>
              <div class="col-lg-4">
                <label>Home Phone</label>
                <input type="number" class="form-control" name="phone" [formControl]="patDemoForm.controls['phone']" placeholder="Enter Phone" />
                <span class="form-text text-danger" *ngIf="
                    patDemoForm.controls['phone'].hasError('required') &&
                    patDemoForm.controls['phone'].touched
                  ">This field is required</span
                >
              </div>
              <div class="col-lg-4">
                <label>Mobile Phone</label>
                <input
                  type="number"
                  class="form-control"
                  name="sms"
                  [formControl]="patDemoForm.controls['sms']"
                  placeholder="Enter Phone No."
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label>Language</label>
                <input
                  type="text"
                  class="form-control"
                  name="language"
                  [formControl]="patDemoForm.controls['language']"
                  placeholder="Enter Language"
                />
              </div>
              <div class="col-lg-4">
                <label>CHA Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="chaName"
                  [formControl]="patDemoForm.controls['chaName']"
                  placeholder=""
                />
              </div>
              <div class="col-lg-4">
                <label>Insurance & Other Services</label>
                <textarea
                  type="text"
                  class="form-control"
                  name="insuranceAndOtherServices"
                  [formControl]="
                    patDemoForm.controls['insuranceAndOtherServices']
                  "
                  placeholder="Insurance & Other Services ..."
                  row="3"
                ></textarea>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label>Primary Care Practice Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="pracName"
                  [formControl]="
                    patDemoForm.controls['practice'].controls['name']
                  "
                  placeholder="Enter Practice Name"
                />
              </div>
              <div class="col-lg-4">
                <label>Practice Phone</label>
                <input
                  type="number"
                  class="form-control"
                  name="pracName"
                  [formControl]="
                    patDemoForm.controls['practice'].controls['phone']
                  "
                  placeholder="Enter Practice Phone"
                />
              </div>
              <div class="col-lg-4">
                <label>Practice Email</label>
                <input
                  type="email"
                  class="form-control"
                  name="pracEmail"
                  [formControl]="
                    patDemoForm.controls['practice'].controls['email']
                  "
                  placeholder="Enter Practice Email"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    patDemoForm.controls['practice'].controls['email'].hasError(
                      'email'
                    ) &&
                    patDemoForm.controls['practice'].controls['email'].touched
                  "
                  >Enter valid Email</span
                >
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label>Primary Care Provider Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="providerName"
                  [formControl]="
                    patDemoForm.controls['provider'].controls['name']
                  "
                  placeholder="Enter Practice Provider"
                />
              </div>
              <div class="col-lg-4">
                <label>Provider Phone</label>
                <input
                  type="number"
                  class="form-control"
                  name="providerPhone"
                  [formControl]="
                    patDemoForm.controls['provider'].controls['phone']
                  "
                  placeholder="Enter Provider Phone"
                />
              </div>
              <div class="col-lg-4">
                <label>Provider Email</label>
                <input
                  type="email"
                  class="form-control"
                  name="providerEmail"
                  [formControl]="
                    patDemoForm.controls['provider'].controls['email']
                  "
                  placeholder="Enter Provider Email"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    patDemoForm.controls['provider'].controls['email'].hasError(
                      'email'
                    ) &&
                    patDemoForm.controls['provider'].controls['email'].touched
                  "
                  >Enter valid Email</span
                >
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label>Practice Key Contact</label>
                <input
                  type="text"
                  class="form-control"
                  name="practiceKeyContactName"
                  [formControl]="
                    patDemoForm.controls['practiceKeyContact'].controls[
                      'contact'
                    ]
                  "
                  placeholder="Enter Practice Contact"
                />
              </div>
              <div class="col-lg-4">
                <label>Key Contact Phone</label>
                <input
                  type="number"
                  class="form-control"
                  name="practiceKeyPhone"
                  [formControl]="
                    patDemoForm.controls['practiceKeyContact'].controls['phone']
                  "
                  placeholder="Key Contact Phone"
                />
              </div>
              <div class="col-lg-4">
                <label>Key Contact Email</label>
                <input
                  type="email"
                  class="form-control"
                  name="practiceKeyEmail"
                  [formControl]="
                    patDemoForm.controls['practiceKeyContact'].controls['email']
                  "
                  placeholder="Key Contact Email"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    patDemoForm.controls['practiceKeyContact'].controls[
                      'email'
                    ].hasError('email') &&
                    patDemoForm.controls['practiceKeyContact'].controls['email']
                      .touched
                  "
                  >Enter valid Email</span
                >
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label>Caregiver Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="caregiverName"
                  [formControl]="
                    patDemoForm.controls['caregiver'].controls['name']
                  "
                  placeholder="Enter Caregiver Name"
                />
              </div>
              <div class="col-lg-4">
                <label>Caregiver Phone</label>
                <input
                  type="number"
                  class="form-control"
                  name="caregiverPhone"
                  [formControl]="
                    patDemoForm.controls['caregiver'].controls['phone']
                  "
                  placeholder="Caregiver Phone"
                />
              </div>
              <div class="col-lg-4">
                <label>MRN At PCP</label>
                <input
                  type="number"
                  class="form-control"
                  name="mrn"
                  [formControl]="patDemoForm.controls['mrn']"
                  placeholder="MRN"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-4">
                <label>Which one of these are you?</label>
                <div class="radio-list">
                  <label class="radio radio-solid">
                    <input
                      type="radio"
                      name="nonPatient"
                      [formControl]="patDemoForm.controls['nonPatient']"
                      value="patient"
                    />
                    <span></span>I am a PATIENT at one of these clinics</label>
                <label class="radio radio-solid">
                    <input
                      type="radio"
                      name="nonPatient"
                      [formControl]="patDemoForm.controls['nonPatient']"
                      value="caregiver"
                    />
                    <span></span>I am a CAREGIVER for a patient from one of these clinics</label
                  >

                  <label class="radio radio-solid">
                    <input
                      type="radio"
                      name="nonPatient"
                      [formControl]="patDemoForm.controls['nonPatient']"
                      value="not patient/caregiver"
                    />
                    <span></span>I am NOT a patient/caregiver for a patient from one of these clinics</label
                  >
                </div>


              </div>
              <div class="col-lg-4">
                <label>Outreach Ok?</label>
                <div class="radio-inline">
                    <label class="radio radio-solid">
                    <input
                      type="radio"
                      name="outReach"
                      [formControl]="patDemoForm.controls['outReach']"
                      value="yes"
                    />
                    <span></span>Yes</label
                  >
                  <label class="radio radio-solid">
                    <input
                      type="radio"
                      name="outReach"
                      [formControl]="patDemoForm.controls['outReach']"
                      value="no"
                    />
                    <span></span>No</label
                  >
                </div>
              </div>
             
            </div>

            <div class="row">
              <div class="col-lg-12">
                <button
                  type="submit"
                  class="btn btn-primary mr-2 pull-right"
                  (click)="submitPatDemoForm()"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>

<!--begin::Row-->
<div
  class="row"
  *ngIf="
    mode == 'edit' &&
    surveyData &&
    surveyData.length != 0 &&
    noteData &&
    showSpinner == false
  "
>
  <div class="col-xl-9">
    <!--begin::Advance Table Widget 10-->
    <div class="card card-custom gutter-b card-stretch" id="survey-card-strech">
      <!--begin::Header-->
      <div
        class="card-header border-0 py-5 px-5"
        style="justify-content: flex-start"
      >
        <h3 class="card-title align-items-start flex-column mr-2 mt-6">
          <span class="card-label font-weight-bolder opacity-70">{{
            surveyTitle | date: "MMM dd, yyyy"
          }}</span>
          <!-- <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
            surveyTitle | date: "MMM dd,yyyy"
          }}</span> -->
          <!-- <div ngbDropdown container="body">
            <button
              class="btn btn-outline-secondary btn-sm bg-hover-light"
              ngbDropdownToggle
            >
              {{ surveyTitle | date
              }}<i class="fa fa-angle-down ml-2" aria-hidden="true"></i>
            </button>
            <div ngbDropdownMenu>
              <button
                ngbDropdownItem
                *ngFor="let survey of surveyData; index as i"
                (click)="setSurvey(i, survey.date)"
              >
                {{ survey.date | date }}
              </button>
            </div>
          </div> -->
        </h3>
        <span style="" class="mt-2">
          <ng-template #popSurveyContent>
            <div
              class="row"
              *ngIf="currentSurvey.notes.length != 0"
              style="max-height: 200px; overflow-x: auto"
            >
              <div class="col-xl-12">
                <div
                  *ngFor="let note of currentSurvey.notes; index as ind"
                  class="d-flex align-items-center bg-hover-light p-2"
                >
                  <div
                    class="d-flex flex-column flex-grow-1 mr-2"
                    style="position: relative"
                  >
                    <span
                      class="text-muted font-weight-bold font-size-lg my-1"
                      >{{ note.note }}</span
                    >
                    <span class="text-muted font-weight-bold font-size-sm">
                      <input
                        class="form-control"
                        placeholder="yyyy-mm-dd"
                        name="srDate"
                        (dateSelect)="onSurveyDateSelect($event, ind)"
                        ngbDatepicker
                        #srDate="ngbDatepicker"
                        style="display: none" /><button
                        class="btn btn-xs p-0 text-left"
                        (click)="srDate.toggle()"
                      >
                        <span class="text-muted font-weight-bold font-size-sm"
                          >Created by
                          <span class="text-primary">
                            {{
                              note.createdBy ? note.createdBy : "Demo"
                            }} </span
                          >on {{ note.date | date: "M/d/y, h:mm a"
                          }}<i
                            class="fa fa-calendar ml-1"
                            style="font-size: 0.9rem"
                          ></i
                        ></span></button
                    ></span>

                    <!-- <p class="text-dark-75 m-0 font-weight-bolder">
                    {{ note.note }}
                  </p> -->
                    <!-- <span
                      class="text-dark-50 font-weight-bolder font-size-xs"
                      >
                      <input
                        class="form-control"
                        placeholder="yyyy-mm-dd"
                        name="srDate"
                        (dateSelect)="onSurveyDateSelect($event, ind)"
                        ngbDatepicker
                        #srDate="ngbDatepicker"
                        style="display: none"
                      />
                      <button class="btn btn-xs p-0" (click)="srDate.toggle()">
                       <small  class="text-dark-50 font-weight-bolder font-size-xs">{{note.createdBy? note.createdBy:"Demo"}} on {{
                        note.date | date:'M/d/y, h:mm a'
                      }}<i class="fa fa-calendar ml-1" style="font-size: 0.9rem"></i></small> 
                      </button>
                    </span> -->
                  </div>
                  <a
                    href="javascript:void(0)"
                    class="font-weight-bold text-success py-1 font-size-lg cursor-pointer"
                    (click)="deleteSurveyNote(ind)"
                    ><i class="fa fa-trash" aria-hidden="true"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-xl-12">
                <textarea
                  type="text"
                  class="form-control"
                  rows="2"
                  [(ngModel)]="surveyNoteText"
                  name="addSurveyNote"
                  placeholder="Note..."
                  style="width: 100% !important"
                ></textarea>
              </div>
              <div class="col-xl-12 mt-2">
                <button
                  type="reset"
                  class="btn btn-secondary btn-sm pull-right cursor-pointer"
                  (click)="s.close(); closePopOver()"
                >
                  Close
                </button>
                <button
                  type="reset"
                  class="btn btn-primary btn-sm mr-2 pull-right cursor-pointer"
                  (click)="saveSurveyNote();s.close()"
                >
                  Save
                </button>
              </div>
            </div>
          </ng-template>
          <ng-template #popSurveyTitle
            ><div class="font-weight-bold">General Notes</div></ng-template
          >

          <div
            class="d-flex align-items-center bg-hover-light p-2"
            placement="bottom"
            [autoClose]="'outside'"
            triggers="manual"
            #s="ngbPopover"
            (click)="s.open(); openPopOver()"
            [ngbPopover]="popSurveyContent"
            [popoverTitle]="popSurveyTitle"
            style="min-width: 250px; height: 100%"
          >
            <!--begin::Icon-->

            <!--end::Icon-->
            <!--begin::Title-->
            <div class="d-flex flex-column mr-2" style="max-width: 280px">
              <span
                class="text-muted font-weight-bold font-size-lg my-1 single-line-ellipses"
                *ngIf="currentSurvey.notes.length != 0"
                >{{ currentSurvey.notes[0].note }}</span
              >
              <span
                class="text-muted font-weight-bold font-size-lg my-1 single-line-ellipses"
                *ngIf="currentSurvey.notes.length == 0"
                >Add Note</span
              >
              <span
                class="text-muted font-weight-bold font-size-sm"
                *ngIf="currentSurvey.notes.length != 0"
                >Created by
                <span class="text-primary font-weight-bold">
                  {{
                    currentSurvey.notes[0].createdBy
                      ? currentSurvey.notes[0].createdBy
                      : "Demo"
                  }} </span
                >on
                {{ currentSurvey.notes[0].date | date: "M/d/y, h:mm a" }}</span
              >

              <!-- <p
                class="text-dark-75 m-0 font-weight-bolder ellipses"
                *ngIf="currentSurvey.notes.length != 0"
              >
                {{ currentSurvey.notes[0].note }}
              </p>
              <p
                class="text-dark-50 m-0 font-weight-bold"
                *ngIf="currentSurvey.notes.length == 0"
              >
                Add Note
              </p>
              <small
              *ngIf="currentSurvey.notes.length != 0"
              class="text-dark-50 font-weight-bolder font-size-xs"
              >{{currentSurvey.notes[0].createdBy? currentSurvey.notes[0].createdBy:"Demo"}} on {{
                currentSurvey.notes[0].date | date:'M/d/y, h:mm a'
              }}</small
            > -->
            </div>
            <!--end::Title-->
            <!--begin::Lable-->
            <a
              href="javascript:void(0)"
              class="font-weight-bold text-success py-1 font-size-lg"
              ><i class="fa fa-sticky-note-o" aria-hidden="true"></i>
              <span
                class="note-count"
                *ngIf="currentSurvey.notes.length != 0"
                >{{ currentSurvey.notes.length }}</span
              ></a
            >
            <!--end::Lable-->
          </div>
        </span>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 px-5">
        <div class="card-scroll">
          <!--begin::Table-->
          <div class="table-responsive">
            <table class="table table-head-custom table-vertical-center">
              <thead>
                <tr class="text-left">
                  <th class="pl-0" style="max-width: 80px">Needs</th>
                  <th style="max-width: 200px">Survey Response</th>
                  <th style="max-width: 50px">CHA Rating</th>
                  <th style="max-width: 150px">Notes</th>
                  <th style="max-width: 100px">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of needMapping; index as i" [ngClass]="{ 
                  'bg-warning-o-30': noteData.needs[0][item.mapWith].status == 'In progress',
                  'bg-success-o-30': noteData.needs[0][item.mapWith].status == 'Referral provided'||noteData.needs[0][item.mapWith].status == 'Service received',
                  'bg-danger-o-30': noteData.needs[0][item.mapWith].status == 'Failed'
                }">
                  <td class="pl-0" style="position: relative">
                    <div
                      *ngIf="
                        item.mapWith == 'other1' ||
                        item.mapWith == 'other2' ||
                        item.mapWith == 'other3'
                      "
                      class="d-flex align-items-center p-0"
                      placement="right"
                      [autoClose]="'outside'"
                      triggers="manual"
                      #n="ngbPopover"
                      (click)="
                        n.open();
                        openNeedPopOver(
                          currentSurvey.data[0][item.mapWith].name
                            ? currentSurvey.data[0][item.mapWith].name
                            : item.name
                        )
                      "
                      [ngbPopover]="popNeedContent"
                      [popoverTitle]="popNeedTitle"
                      popoverClass="transform-none"
                    >
                      <ng-template #popNeedContent>
                        <div class="row mt-2">
                          <div class="col-xl-12">
                            <textarea
                              type="text"
                              class="form-control"
                              rows="2"
                              [(ngModel)]="needText"
                              name="addneed"
                              placeholder="Need..."
                              style="width: 100% !important"
                            ></textarea>
                          </div>
                          <div class="col-xl-12 mt-2">
                            <button
                              type="reset"
                              class="btn btn-secondary btn-sm pull-right cursor-pointer"
                              (click)="n.close()"
                            >
                              Close
                            </button>
                            <button
                              type="reset"
                              class="btn btn-primary btn-sm mr-2 pull-right cursor-pointer"
                              (click)="saveNeed(item.mapWith,n)"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </ng-template>
                      <ng-template #popNeedTitle
                        ><div class="font-weight-bold">Need</div></ng-template
                      >
                      <div
                        class="d-flex flex-column flex-grow-1 mr-2 cursor-pointer"
                        style=""
                      >
                        <p
                          class="text-dark-75 font-weight-bold m-0 font-size-lg ml-2"
                        >
                          {{
                            currentSurvey.data[0][item.mapWith].name
                              ? currentSurvey.data[0][item.mapWith].name
                              : item.name
                          }}
                          <i class="fa fa-pencil ml-2" aria-hidden="true"></i>
                        </p>
                      </div>
                    </div>

                    <span
                      style=""
                      *ngIf="
                        item.mapWith !== 'other1' &&
                        item.mapWith !== 'other2' &&
                        item.mapWith !== 'other3'
                      "
                      class="text-dark-75 font-weight-bold d-block font-size-lg ml-2"
                      >{{
                        currentSurvey.data[0][item.mapWith].name
                          ? currentSurvey.data[0][item.mapWith].name
                          : item.name
                      }}</span
                    >
                  </td>
                  <td style="position: relative"  [ngClass]="{
                    'popover-top-110':
                        i == needMapping.length - 1 ||
                        i == needMapping.length - 2 ||
                        i == needMapping.length - 3
                  }">
                    <!-- <span class="text-dark m-0 font-weight-normal" style="">{{
                      currentSurvey.data[0][item.mapWith].surveyResponse
                    }}</span> -->
                    <div
                      class="d-flex align-items-center bg-hover-light p-0"
                      placement=""
                      [autoClose]="'outside'"
                      triggers="manual"
                      #r="ngbPopover"
                      (click)="
                        r.open();
                        openResPopOver(
                          currentSurvey.data[0][item.mapWith].surveyResponse
                        )
                      "
                      [ngbPopover]="popRespContent"
                      [popoverTitle]="popRespTitle"
                      popoverClass="transform-none"
                    >
                      <ng-template #popRespContent>
                        <div class="row mt-2">
                          <div class="col-xl-12">
                            <textarea
                              type="text"
                              class="form-control"
                              rows="2"
                              [(ngModel)]="respText"
                              name="addresp"
                              placeholder="Response..."
                              style="width: 100% !important"
                            ></textarea>
                          </div>
                          <div class="col-xl-12 mt-2">
                            <button
                              type="reset"
                              class="btn btn-secondary btn-sm pull-right cursor-pointer"
                              (click)="r.close()"
                            >
                              Close
                            </button>
                            <button
                              type="reset"
                              class="btn btn-primary btn-sm mr-2 pull-right cursor-pointer"
                              (click)="saveResp(item.mapWith,r)"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </ng-template>
                      <ng-template #popRespTitle
                        ><div class="font-weight-bold">
                          Response
                        </div></ng-template
                      >  
                      <div
                        class="d-flex flex-column flex-grow-1 mr-2"
                        style="max-width: 180px"
                      > 
                        <p
                          class="text-dark-50 m-0 font-weight-normal ellipses-line-1 pre-wrap-space"
                          *ngIf="
                            currentSurvey.data[0][item.mapWith].surveyResponse
                          "
                          ngbPopover="{{currentSurvey.data[0][item.mapWith].surveyResponse}}" triggers="mouseenter:mouseleave"
                          placement = "bottom"
                        >
                          {{
                            currentSurvey.data[0][item.mapWith].surveyResponse
                          }}
                        </p>
                        <p
                          class="text-dark-50 m-0 font-weight-normal"
                          *ngIf="
                            !currentSurvey.data[0][item.mapWith].surveyResponse
                          "
                        >
                          Add Response
                        </p>
                      </div>
                      <a
                        href="javascript:void(0)"
                        class="font-weight-bold text-success py-1 font-size-lg"
                        ><i
                          class="fa fa-plus"
                          *ngIf="
                            !currentSurvey.data[0][item.mapWith].surveyResponse
                          "
                          aria-hidden="true"
                        ></i>
                        <i
                          class="fa fa-pencil"
                          *ngIf="
                            currentSurvey.data[0][item.mapWith].surveyResponse
                          "
                          aria-hidden="true"
                        ></i
                      ></a>
 
                      <a
                      href="javascript:void(0)"
                      class="font-weight-bold text-success py-1 ml-2 font-size-lg"
                      >
                      <span
                      class="rating-count"
                      *ngIf="
                      currentSurvey.data[0][item.mapWith].surveyResponse
                      "
                      (click)="toggleRatingColor($event,item.mapWith)"
                      [ngClass]="{
                        'bg-color-gray':
                             (!currentSurvey.data[0][item.mapWith].surveyRating && (!currentSurvey.data[0][item.mapWith].status || currentSurvey.data[0][item.mapWith].status !== 'active')) ||
                            (currentSurvey.data[0][item.mapWith].surveyRating < 3 && (!currentSurvey.data[0][item.mapWith].status || currentSurvey.data[0][item.mapWith].status !== 'active')) || 
                            (currentSurvey.data[0][item.mapWith].status && currentSurvey.data[0][item.mapWith].status === 'inactive') 
                      }"
                      >{{
                        currentSurvey.data[0][item.mapWith].surveyRating
                      }}</span
                    >
                    </a>
                    </div>

                    <!-- <div
                      *ngIf="
                        item.mapWith !== 'other1' &&
                        item.mapWith !== 'other2' &&
                        item.mapWith !== 'other3'
                      "
                      class="d-flex flex-column flex-grow-1 mr-2"
                      style="max-width: 150px"
                    >
                      <p class="text-dark-50 m-0 font-weight-normal">
                        {{ item.surveyResponse }}
                      </p>
                    </div> -->
                  </td>
                  <td>
                    <div ngbDropdown container="body">
                      <button
                        class="btn btn-outline-secondary btn-sm bg-hover-light"
                        ngbDropdownToggle
                      >
                        {{ noteData.needs[0][item.mapWith].chaRating
                        }}<i
                          class="fa fa-angle-down ml-2"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <div ngbDropdownMenu>
                        <button
                          ngbDropdownItem
                          *ngFor="let option of responseOption"
                          (click)="setRating(i, option.name)"
                        >
                          {{ option.name }}
                        </button>
                      </div>
                    </div>
                  </td>
                  <td
                    style="position: relative"
                    [ngClass]="{
                      'popover-top-110':
                        noteData.needs[0][item.mapWith].notes.length == 0 &&
                        (i == needMapping.length - 1 ||
                          i == needMapping.length - 2 ||
                          i == needMapping.length - 3),
                      'popover-top-180':
                        noteData.needs[0][item.mapWith].notes.length != 0 &&
                        (i == needMapping.length - 1 ||
                          i == needMapping.length - 2 ||
                          i == needMapping.length - 3)
                    }"
                  >
                    <ng-template #popContent>
                      <div
                        class="row"
                        *ngIf="
                          noteData.needs[0][item.mapWith].notes.length != 0
                        "
                        style="max-height: 100px; overflow-x: auto"
                      >
                        <div class="col-xl-12">
                          <div
                            *ngFor="
                              let note of noteData.needs[0][item.mapWith].notes;
                              index as ind
                            "
                            class="d-flex align-items-center bg-hover-light p-2"
                          >
                            <div
                              class="d-flex flex-column flex-grow-1 mr-2"
                              style="position: relative"
                            >
                              <span
                                class="text-muted font-weight-bold font-size-lg my-1"
                                >{{ note.note }}</span
                              >
                              <span
                                class="text-muted font-weight-bold font-size-sm"
                              >
                                <input
                                  class="form-control"
                                  placeholder="yyyy-mm-dd"
                                  name="ntDate"
                                  (dateSelect)="
                                    onNoteDateSelect($event, item.mapWith, ind)
                                  "
                                  ngbDatepicker
                                  #ntDate="ngbDatepicker"
                                  style="display: none" /><button
                                  class="btn btn-xs p-0 text-left"
                                  (click)="ntDate.toggle()"
                                >
                                  <span
                                    class="text-muted font-weight-bold font-size-sm"
                                    >Created by
                                    <span class="text-primary">
                                      {{
                                        note.createdBy ? note.createdBy : "Demo"
                                      }} </span
                                    >on {{ note.date | date: "M/d/y, h:mm a"
                                    }}<i
                                      class="fa fa-calendar ml-1"
                                      style="font-size: 0.9rem"
                                    ></i
                                  ></span></button
                              ></span>
                              <!-- <p class="text-dark-75 m-0 font-weight-bolder">
                              {{ note.note }}
                            </p> -->
                              <!-- <span
                              class="text-dark-50 font-weight-bolder font-size-xs"
                              >
                              <input
                              class="form-control"
                              placeholder="yyyy-mm-dd"
                              name="ntDate"
                              (dateSelect)="
                                onNoteDateSelect($event, item.mapWith, ind)
                              "
                              ngbDatepicker
                              #ntDate="ngbDatepicker"
                              style="display: none"
                            />
                              <button class="btn btn-xs p-0" (click)="ntDate.toggle()">
                               <small  class="text-dark-50 font-weight-bolder font-size-xs">{{note.createdBy? note.createdBy:"Demo"}} on {{
                                note.date | date:'M/d/y, h:mm a'
                              }}<i class="fa fa-calendar ml-1" style="font-size: 0.9rem"></i></small> 
                              </button>
                            </span> -->
                            </div>
                            <a
                              href="javascript:void(0)"
                              class="font-weight-bold text-success py-1 font-size-lg cursor-pointer"
                              (click)="deleteNote(item.mapWith, ind)"
                              ><i class="fa fa-trash" aria-hidden="true"></i
                            ></a>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-xl-12">
                          <textarea
                            type="text"
                            class="form-control"
                            rows="2"
                            [(ngModel)]="noteText"
                            name="addNote"
                            placeholder="Note..."
                            style="width: 100% !important"
                          ></textarea>
                        </div>
                        <div class="col-xl-12 mt-2">
                          <button
                            type="reset"
                            class="btn btn-secondary btn-sm pull-right cursor-pointer"
                            (click)="p.close(); closePopOver()"
                          >
                            Close
                          </button>
                          <button
                            type="reset"
                            class="btn btn-primary btn-sm mr-2 pull-right cursor-pointer"
                            (click)="saveNote(item.mapWith);p.close()"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #popTitle
                      ><div class="font-weight-bold">Notes</div></ng-template
                    >

                    <div
                      class="d-flex align-items-center bg-hover-light p-0 px-2"
                      placement=""
                      [autoClose]="'outside'"
                      triggers="manual"
                      #p="ngbPopover"
                      (click)="p.open(); openPopOver()"
                      [ngbPopover]="popContent"
                      [popoverTitle]="popTitle"
                      popoverClass="transform-none"
                    >
                      <!--begin::Icon-->

                      <!--end::Icon-->
                      <!--begin::Title-->
                      <div
                        class="d-flex flex-column flex-grow-1 mr-2"
                        style="max-width: 150px"
                      >
                        <span
                          class="text-muted font-weight-bold font-size-lg my-1 ellipses"
                          *ngIf="
                            noteData.needs[0][item.mapWith].notes.length != 0
                          "
                        >
                          {{
                            noteData.needs[0][item.mapWith].notes[0].note
                          }}</span
                        >
                        <span
                          class="text-muted font-weight-bold font-size-lg my-1 ellipses"
                          *ngIf="
                            noteData.needs[0][item.mapWith].notes.length == 0
                          "
                          >Add Note</span
                        >
                        <span
                          class="text-muted font-weight-bold font-size-sm"
                          *ngIf="
                            noteData.needs[0][item.mapWith].notes.length != 0
                          "
                          >Created by
                          <span class="text-primary font-weight-bold">
                            {{
                              noteData.needs[0][item.mapWith].notes[0].createdBy
                                ? noteData.needs[0][item.mapWith].notes[0]
                                    .createdBy
                                : "Demo"
                            }} </span
                          >on
                          {{
                            noteData.needs[0][item.mapWith].notes[0].date
                              | date: "M/d/y, h:mm a"
                          }}</span
                        >

                        <!-- <p
                          class="text-dark-75 m-0 font-weight-bolder ellipses"
                          *ngIf="noteData.needs[0][item.mapWith].notes.length != 0"
                        >
                          {{ noteData.needs[0][item.mapWith].notes[0].note }}
                        </p>
                        <p
                          class="text-dark-50 m-0 font-weight-bold"
                          *ngIf="
                            noteData.needs[0][item.mapWith].notes.length == 0
                          "
                        >
                          Add Note
                        </p>
                        <small
                        *ngIf="
                          noteData.needs[0][item.mapWith].notes.length != 0
                        "
                        class="text-dark-50 font-weight-bolder font-size-xs"
                        >{{noteData.needs[0][item.mapWith].notes[0].createdBy? noteData.needs[0][item.mapWith].notes[0].createdBy:"Demo"}} on {{
                          noteData.needs[0][item.mapWith].notes[0].date | date:'M/d/y, h:mm a'
                        }}</small
                      > -->
                      </div>
                      <!--end::Title-->
                      <!--begin::Lable-->
                      <a
                        href="javascript:void(0)"
                        class="font-weight-bold text-success py-1 font-size-lg"
                        ><i class="fa fa-sticky-note-o" aria-hidden="true"></i>
                        <span
                          class="note-count"
                          *ngIf="
                            noteData.needs[0][item.mapWith].notes.length != 0
                          "
                          >{{
                            noteData.needs[0][item.mapWith].notes.length
                          }}</span
                        ></a
                      >
                      <!--end::Lable-->
                    </div>
                  </td>
                  <td class="pr-0 overflow-hidden">
                    <div ngbDropdown container="body">
                      <button
                        class="btn btn-outline-secondary btn-sm mr-2 bg-hover-light"
                        ngbDropdownToggle
                      > 
                        {{ noteData.needs[0][item.mapWith].status
                        }}<i
                          class="fa fa-angle-down ml-2"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <div ngbDropdownMenu>
                        <button
                          ngbDropdownItem
                          *ngFor="let option of statusOption"
                          (click)="setStatus(i, option.name)"
                        >
                          {{ option.name }}
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end::Table-->
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Advance Table Widget 10-->
  </div>
  <div class="col-xl-3">
    <!--begin::Tiles Widget 8-->
    <div class="card card-custom gutter-b card-stretch" id="note-card-strech">
      <div class="card-header border-0 py-5 px-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder pb-2">Recent Notes</span>
        </h3>
      </div>

      <!--begin::Body-->
      <div class="card-body pt-0 px-5">
        <div class="card-scroll" style="overflow-x: hidden;">
          <div class="" *ngIf="allNotes.length != 0">
            <div
              *ngFor="let note of allNotes"
              class="d-flex flex-wrap align-items-center p-2 bg-hover-light"
            >
              <!-- <div
                class="d-flex flex-column flex-grow-1 my-lg-0 my-2"
                *ngIf="note.note"
              >
                <span class="font-weight-bolder text-dark pb-0"
                  >{{ note.for ? note.for : "" }}
                  </span
                >
                <small class="text-dark-50 font-size-xs font-weight-bolder">{{note.createdBy? note.createdBy:"Demo"}} on {{
                  note.date | date:'M/d/y, h:mm a'
                }}</small>
                <p class="text-dark-50 m-0 font-weight-normal ellipses">
                  {{ note.note }}
                </p>
              </div> -->

              <!--begin::Title-->
              <div
                class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3"
                *ngIf="note.note"
              >
                <a
                  href="javascript:void(0)"
                  class="text-dark-75 font-weight-bold text-hover-primary font-size-lg"
                  >{{ note.for ? note.for : "" }}</a
                >
                <span
                  class="text-muted font-weight-bold font-size-lg my-1"
                  >{{ note.note }}</span
                >
                <span class="text-muted font-weight-bold font-size-sm"
                  >Created by
                  <span class="text-primary font-weight-bold">
                    {{ note.createdBy ? note.createdBy : "Demo" }} </span
                  >on {{ note.date | date: "M/d/y, h:mm a" }}</span
                >
              </div>
              <!--end::Title-->
            </div>
          </div>
        </div>

        <!-- <div class="resize-triggers"><div class="expand-trigger"><div style="width: 344px; height: 430px;"></div></div><div class="contract-trigger"></div></div> -->
        <!--end::Tiles Body-->
      </div>
      <!--end::Tiles Widget 8-->
    </div>
  </div>
</div>
<!--end::Row-->