import { Component, OnInit } from "@angular/core";
import { UserService } from "./../user.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { MessageService } from "../../shared/service/message/message.service";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  userForm: FormGroup;
  showSpinner: any;
  initialLoadDone: boolean = false;
  curUserId: string = "";
  mode: string = "new";
  pwdValid: boolean = true;
  userObject: any;
  constructor(
    private userService: UserService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private messageService: MessageService
  ) {
    this.userObject = {};
    this.showSpinner = true;
    this.curUserId = "";
    this.mode = "new";
    this.userForm = this.fb.group({
      fname: ["", Validators.compose([Validators.required])],
      lname: [""],
      email: ["", Validators.compose([Validators.required, Validators.email])],
      password: ["", Validators.compose([Validators.required])],
      type: [""],
      adminId: [""],
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.curUserId = params.id;
      if (this.curUserId != "new") {
        this.mode = "edit";
        this.showSpinner = true;
      } else {
        this.mode = "new";
        this.showSpinner = false;
      }
    });
    if (this.curUserId && this.curUserId != "new") {
      this.getUserById();
    } else {
      this.showSpinner = false;
    }
  }

  passwordValidate(pwd) {
    let checkSpecial = /[*@!#%&$()^~{}]+/.test(pwd),
      checkUpper = /[A-Z]+/.test(pwd),
      checkLower = /[a-z]+/.test(pwd),
      checkNumber = /[0-9]+/.test(pwd),
      checKLength = pwd.length;
    let res = false;
    if (
      checkUpper &&
      checkLower &&
      checkSpecial &&
      checKLength >= 8 &&
      checkNumber
    ) {
      res = true;
    }
    return res;
  }

  getUserById() {
    this.userService.getUserById(this.curUserId).subscribe((response) => {
      if (response) {
        if (response.error === false) {
          if (response.data) {
            this.userObject = response.data;
            this.setUserObjectInForm();
          }
        }
        this.showSpinner = false;
      } else {
        this.showSpinner = false;
      }
    });
  }

  setUserObjectInForm() {
    this.userForm.get('password').clearValidators();
    this.userForm.setValue({
      fname: this.userObject.fname,
      lname: this.userObject.lname,
      email: this.userObject.email,
      password: "",
      type: this.userObject.type,
      adminId: this.userObject.adminId,
    });
  }

  onChangePwd() {
    
    let checkPwd = this.passwordValidate(
      this.userForm.controls["password"].value
    );
    if (checkPwd == true) {
      if (this.pwdValid == false) {
        this.pwdValid = true;
      }
    }
   
      if(this.userForm.controls["password"].value == "" || this.userForm.controls["password"].value == null){
        this.pwdValid = true;
      }
  }

  saveUser() {
    const controls = this.userForm.controls;

    if (this.userForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    const userObject = {
      fname: controls.fname.value,
      lname: controls.lname.value,
      email: controls.email.value,
      password: controls.password.value,
    };
    if (this.mode == "edit") {
      userObject["type"] = controls.type.value;
      userObject["adminId"] = controls.adminId.value;
    } else {
      userObject["type"] = "user";
      userObject["adminId"] = "";
    }

    if (this.mode == "edit") {
      let processToUpdate = false;
      if (userObject["password"] == "") {
        this.pwdValid = true;
        processToUpdate = true;
        delete userObject["password"];
      } else {
        let checkPwd = this.passwordValidate(controls.password.value);
        if (checkPwd == false) {
          this.pwdValid = false;
          return;
        } else {
          processToUpdate = true;
        }
      }

      this.userService
        .updateUser(this.curUserId, userObject)
        .subscribe((response) => {
          if (response) {
            if (response.error === false) {
              this.toastr.success(this.messageService.messages.userUpdated);
              this.router.navigate([`users`]);
            } else {
              if (response.message && response.message == "user exist") {
                this.toastr.error(this.messageService.messages.userExist);
              } else {
                this.toastr.error(this.messageService.messages.someIssue);
              }
            }
          } else {
            this.toastr.error(this.messageService.messages.someIssue);
          }
        });
    } else {
      let checkPwd = this.passwordValidate(controls.password.value);
      if (checkPwd == false) {
        this.pwdValid = false;
        return;
      }

      this.userService.saveUser(userObject).subscribe((response) => {
        if (response) {
          if (response.error === false) {
            this.toastr.success(this.messageService.messages.userSaved);
            this.router.navigate([`users`]);
          } else {
            if (response.message && response.message == "user exist") {
              this.toastr.error(this.messageService.messages.userExist);
            } else {
              this.toastr.error(this.messageService.messages.someIssue);
            }
          }
        } else {
          this.toastr.error(this.messageService.messages.someIssue);
        }
      });
    }
  }
}
