import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
interface response {
  statusCode: any;
  data: any;
  error: any;
}
@Injectable({
  providedIn: "root",
})
export class UserService {
  responseData: any;
  constructor(private http: HttpClient, private router: Router) {}
  getAllUser(data): Observable<any> {
    this.responseData = this.http.post<response>(
      environment.apiEndpoint + "user/get",
      data
    );
    return this.responseData;
  }
  deleteUser(id: any, data: any): Observable<any> {
    this.responseData = this.http.put<response>(
      environment.apiEndpoint + "user/" + id,
      data
    );
    return this.responseData;
  }
  saveUser(data): Observable<any> {
    this.responseData = this.http.post<response>(
      environment.apiEndpoint + "user",
      data
    );
    return this.responseData;
  }
  updateUser(id: any, data: any): Observable<any> {
    this.responseData = this.http.put<response>(
      environment.apiEndpoint + "user/" + id,
      data
    );
    return this.responseData;
  }
  getUserById(id: any): Observable<any> {
    this.responseData = this.http.get<response>(
      environment.apiEndpoint + "user/" + id
    );
    return this.responseData;
  }
}
