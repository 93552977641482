// Angular
import { Injectable } from "@angular/core";
import { 
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
// RxJS
import { Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { MessageService } from "../message/message.service";

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  constructor(
    private messageService: MessageService,
    private toastr: ToastrService,
    private router: Router
  ) {}
  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // tslint:disable-next-line:no-debugger
    // modify request

    request = request.clone({
      withCredentials: true
    });

    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
          }
        },
        (error) => {
          if (
            error.status === 401 ||
            error.status === 400 ||
            error.status === 403
          ) {
            if(error.error && error.error.message && error.error.message == "Not have access to this page"){
            this.toastr.error(this.messageService.messages.pageAccess);
            this.router.navigate([`login`]);
            }else{
            localStorage.removeItem("userInfo");
            this.toastr.error(this.messageService.messages.authFailed);
            this.router.navigate([`login`]);
            }
            
          }
        }
      )
    );
  }
}
