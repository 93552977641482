import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BaseComponent } from "./shared/component/base/base.component";
import { PatientComponent } from './patient/patient/patient.component';
import { UserComponent } from "./user/user/user.component";
 
const routes: Routes = [
  {
    path: "patients",
    component: BaseComponent,
    loadChildren: () =>
      import("./patient/patient.module").then((m) => m.PatientModule),
  },
  {
    path: "patient/:id",
    component: BaseComponent,
    children: [
      {
        path: "",
        component: PatientComponent,
        pathMatch: "full",
      }
    ]},
  {
    path: "users",
    component: BaseComponent,
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
  },
  {
    path: "user/:id",
    component: BaseComponent,
    children: [
      {
        path: "",
        component: UserComponent,
        pathMatch: "full",
      }
    ]},
  { 
    path: "",
    loadChildren: () =>
    import("./session/session.module").then((m) => m.SessionModule)
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
