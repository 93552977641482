<div class="row mt-10">
  <div class="col-xl-12">
    <div class="card card-custom gutter-b card-stretch" id="">
      <div class="card-header border-0 py-5 px-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder pb-2"
            >User Details</span
          >
        </h3>
      </div>
      <div class="card-body pt-0 px-5">
        <div class="">
          <form
            class="form"
            [formGroup]="userForm"
            autocomplete="off"
            novalidate="novalidate"
          >
            <div class="form-group row">
              <div class="col-lg-12">
                <label>First Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="fname"
                  [formControl]="userForm.controls['fname']"
                  placeholder="Enter First name"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    userForm.controls['fname'].hasError('required') &&
                    userForm.controls['fname'].touched
                  "
                  >Please enter first name</span
                >
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-12">
                <label>Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="lname"
                  placeholder="Enter Last Name"
                  [formControl]="userForm.controls['lname']"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    userForm.controls['lname'].hasError('required') &&
                    userForm.controls['lname'].touched
                  "
                  >Please enter last name</span
                >
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-12">
                <label>Email</label>
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  [formControl]="userForm.controls['email']"
                  placeholder="Enter Practice Email"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    (userForm.controls['email'].hasError('email') &&
                      userForm.controls['email'].touched) ||
                    (userForm.controls['email'].hasError('required') &&
                      userForm.controls['email'].touched)
                  "
                  >Enter valid Email</span
                >
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-12">
                <label>Password</label>
                <input
                  type="password"
                  class="form-control"
                  name="password"
                  [formControl]="userForm.controls['password']"
                  placeholder="Enter Practice Password"
                  (keyup)="onChangePwd()"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    userForm.controls['password'].hasError('required') &&
                    userForm.controls['password'].touched
                  "
                  >Password is required</span
                >
                <span
                  class="form-text text-danger"
                  *ngIf="
                    pwdValid == false && userForm.controls['password'].touched
                  "
                  >Password must be valid (contains uppercase, lowercase,
                  number,special character and min 8 characters)</span
                >
              </div>
            </div>

            <input
              type="hidden"
              class="form-control"
              name="adminId"
              [formControl]="userForm.controls['adminId']"
            />

            <input
              type="hidden"
              class="form-control"
              name="type"
              [formControl]="userForm.controls['type']"
            />

            <div class="row pb-2">
              <div class="col-lg-12">
                <button
                  type="submit"
                  class="btn btn-primary mr-2 pull-right"
                  (click)="saveUser()"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
