import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";

interface response {
  statusCode: any;
  data: any;
  error: any;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  responseData: any;
  constructor(private http: HttpClient, private router: Router) { }

  getUserInfo(){
    const userInfo = localStorage.getItem("userInfo");
    let userObj = {};
    if (
      userInfo !== null &&
      userInfo !== undefined &&
      userInfo !== ""
    ) {
      userObj = JSON.parse(userInfo);
    }
    return userObj;
  }

   logout() {
    this.responseData = this.http.get<response>(
      environment.apiEndpoint + "logout"
    );
    return this.responseData;
  }
}
 