import { Component, OnInit } from "@angular/core";
import { PatientService } from "./../patient.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { MessageService } from "../../shared/service/message/message.service";
import { AuthService } from "../../shared/service/auth/auth.service";
import { ConstantService } from "../../shared/service/constant/constant.service";
  
@Component({
  selector: "app-patient",
  templateUrl: "./patient.component.html",
  styleUrls: ["./patient.component.scss"],
})
export class PatientComponent implements OnInit {
  needMapping: any;
  surveyTitle: any;
  statusOption: any;
  responseOption: any;
  surveyData: any;
  noteData: any;
  patDemoForm: FormGroup;
  currentSurvey: any;
  patDemoId: any;
  noteText: any;
  allNotes: any;
  surveyNoteText: any;
  mode: any;
  overallStatusOption: any;
  task: any;
  teamReview: any;
  urgentIssue: any;
  patientData: any;
  showSpinner: any;
  respText: any;
  needText: any;
  noteDate: any;
  userName: any;
  patImportData: any;
  surveySubScale: any;
  overAllStatusModel: any;
  constructor(
    private patientService: PatientService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private messageService: MessageService,
    private authService: AuthService,
    private constantService: ConstantService
  ) {
    this.patImportData = [];
    this.surveySubScale = [];
    this.overAllStatusModel = "Not started";
    this.userName = "";
    this.noteDate = "";
    this.showSpinner = true;
    this.respText = "";
    this.needText = "";
    this.patientData = null;
    this.mode = "new";
    this.task = [];
    this.teamReview = false;
    this.urgentIssue = false;
  }

  ngAfterViewInit() {}

  ngOnInit(): void {
    this.initPatDemoForm();
    let userInfo = this.authService.getUserInfo();
    this.userName = userInfo["name"] ? userInfo["name"] : "";
    this.overallStatusOption = this.constantService.constant.overallStatusOption;
    this.allNotes = [];
    this.noteText = "";
    this.surveyNoteText = "";
    this.surveyData = [];
    this.surveyTitle = "";
    this.currentSurvey = {};
    this.noteData = "";
    this.patDemoId = "";
    this.statusOption = this.constantService.constant.statusOption;
    this.responseOption = this.constantService.constant.responseOption;
    this.needMapping = this.constantService.constant.needMapping;

    this.route.params.subscribe((params) => {
      this.patDemoId = params.id;
    });
    if (this.patDemoId && this.patDemoId != "new") {
      this.getPatById();
    } else {
      this.showSpinner = false;
    }
  }

  initPatDemoForm() {
    this.patDemoForm = this.fb.group({
      fname: ["", Validators.compose([Validators.required])],
      lname: [""],
      dob: [null],
      gender: [""],
      nonPatient:[""],
      outReach:[""],
      phone: [""],
      chaName: [""],
      language: [""],
      sms: [""],
      insuranceAndOtherServices: [""],
      provider: this.fb.group({
        name: [""],
        email: ["", Validators.compose([Validators.email])],
        phone: [""],
      }),
      practiceKeyContact: this.fb.group({
        contact: [""],
        email: ["", Validators.compose([Validators.email])],
        phone: [""],
      }),
      practice: this.fb.group({
        name: [""],
        email: ["", Validators.compose([Validators.email])],
        phone: [""],
      }),
      caregiver: this.fb.group({
        name: [""],
        phone: [""],
      }),
      overallStatus: ["Not started"],
      mrn: [""],
    });
  }

  getPatById() {
    this.patientService.getPatientById(this.patDemoId).subscribe((response) => {
      if (response) {
        if (response.error === false) {
          if (response.data) {
            this.patientData = response.data;
            let dob = null;
            if (this.patientData.dob) {
              dob = {
                year: new Date(this.patientData.dob).getUTCFullYear(),
                month: new Date(this.patientData.dob).getUTCMonth() + 1,
                day: new Date(this.patientData.dob).getUTCDate(),
              };
            }

            this.patDemoForm.setValue({
              fname: this.patientData.fname,
              lname: this.patientData.lname,
              dob: dob ? dob : null,
              gender: this.patientData.gender ? this.patientData.gender:"",
              nonPatient:this.patientData.nonPatient ? this.patientData.nonPatient:"",
              outReach:this.patientData.outReach ? this.patientData.outReach:"",
              phone: this.patientData.phone ? this.patientData.phone:"",
              chaName: this.patientData.chaName ? this.patientData.chaName:"",
              language: this.patientData.language ? this.patientData.language:"",
              sms: this.patientData.sms ? this.patientData.sms:"",
              insuranceAndOtherServices: this.patientData
                .insuranceAndOtherServices ? this.patientData
                .insuranceAndOtherServices:"",
              provider: {
                name: this.patientData.provider && this.patientData.provider.name ? this.patientData.provider.name:"",
                email: this.patientData.provider && this.patientData.provider.email ? this.patientData.provider.email:"",
                phone: this.patientData.provider && this.patientData.provider.phone ? this.patientData.provider.phone:"",
              },
              practiceKeyContact: {
                contact: this.patientData.practiceKeyContact && this.patientData.practiceKeyContact.contact ? this.patientData.practiceKeyContact.contact:"",
                email: this.patientData.practiceKeyContact && this.patientData.practiceKeyContact.email ? this.patientData.practiceKeyContact.email:"",
                phone: this.patientData.practiceKeyContact && this.patientData.practiceKeyContact.phone ? this.patientData.practiceKeyContact.phone:"",
              },
              practice: {
                name: this.patientData.practice && this.patientData.practice.name ? this.patientData.practice.name:"",
                email: this.patientData.practice && this.patientData.practice.email ? this.patientData.practice.email:"",
                phone: this.patientData.practice && this.patientData.practice.phone ? this.patientData.practice.phone:"",
              },
              caregiver: {
                name: this.patientData.caregiver && this.patientData.caregiver.name ? this.patientData.caregiver.name:"",
                phone: this.patientData.caregiver && this.patientData.caregiver.phone ? this.patientData.caregiver.phone:"",
              },
              overallStatus: this.patientData.overallStatus
                ? this.patientData.overallStatus
                : "Not started",
              mrn: this.patientData.mrn ? this.patientData.mrn : "",
            });
            this.overAllStatusModel = this.patientData.overallStatus
              ? this.patientData.overallStatus
              : "Not started";
            if (this.patientData.tasks && this.patientData.tasks.length != 0) {
              this.task = this.patientData.tasks;
              let teamReviewInd = this.task.indexOf("teamReview");
              if (teamReviewInd > -1) {
                this.teamReview = true;
              }
              let urgentIssueInd = this.task.indexOf("urgentIssue");
              if (urgentIssueInd > -1) {
                this.urgentIssue = true;
              }
            }
            this.mode = "edit";
            this.getNotes();
            this.getSurvey();
            //this.showSpinner = false;
          } else {
            this.showSpinner = false;
            this.mode = "new";
          }
        }
      } else {
        this.showSpinner = false;
      }
    });
  }

  toggleRatingColor(e,map){
    e.stopPropagation();
    if((this.currentSurvey.data[0][map].status && this.currentSurvey.data[0][map].status == 'inactive') || !this.currentSurvey.data[0][map].status){
      this.currentSurvey.data[0][map].status = 'active';
    }
    
    else{
      this.currentSurvey.data[0][map].status = 'inactive';
    }
    var data = {};
    data["data"] = this.currentSurvey.data;
    this.updateSurvey(data);
  }

  openNoteDate(date) {
    this.noteDate = {
      year: new Date(date).getFullYear(),
      month: new Date(date).getMonth(),
      day: new Date(date).getDate(),
    };
  }

  setOverAllStatus(status) {
    this.overAllStatusModel = status;
    let data = {};
    data["overallStatus"] = this.overAllStatusModel;
    this.updatePatDemoInfo(data);
  }
  setStatus(index, status) {
    let mapWith = this.needMapping[index].mapWith;
    this.noteData.needs[0][mapWith].status = status;
    // let data = {};
    // let key = "needs.0." + mapWith + ".status";
    // data[key] = status;
    let data = {};
    data["needs"] = this.noteData.needs;
    this.updateNotes(data);
  }
  setRating(index, rating) {
    let mapWith = this.needMapping[index].mapWith;
    this.noteData.needs[0][mapWith].chaRating = rating;
    // let data = {};
    // let key = "needs.0." + mapWith + ".chaRating";
    // data[key] = rating;
    let data = {};
    data["needs"] = this.noteData.needs;
    this.updateNotes(data);
  }

  saveNote(map) {
    if (this.noteText != undefined && this.noteText != null) {
      this.noteText = this.noteText.trim();
    }
    if (this.noteText) {
      let uid = this.patientService.makeid();
      let noteObj = {
        date: new Date(),
        note: this.noteText,
        createdBy: this.userName,
        uid: uid,
      };
      this.noteData.needs[0][map].notes.unshift(noteObj);
      // let data = { mappedWith: map };
      // data["note"] = noteObj;
      let data = {};
      data["needs"] = this.noteData.needs;
      this.updateNotes(data);

      noteObj["for"] = map
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, function (str) {
          return str.toUpperCase();
        });
      this.allNotes.unshift(noteObj);
    }
  }

  updatePatDemoInfo(data) {
    this.patientService
      .updatePatient(this.patientData._id, data)
      .subscribe((response) => {
        if (response) {
          if (response.error === false) {
            this.toastr.success(this.messageService.messages.patDemoUpdate);
          } else {
            this.toastr.error(this.messageService.messages.someIssue);
          }
        } else {
          this.toastr.error(this.messageService.messages.someIssue);
        }
      });
  }
  parseInfo(info) {
    let surveyInfo = info.split(">");
    let infoArr = [];
    for (var i = 0; i < surveyInfo.length; i++) {
      let infoObj = {};
      if (surveyInfo[i]) {
        let values = surveyInfo[i].split("<");
        let mapWith = values[1].split("-")[0];
        infoObj["mapWith"] = mapWith.trim();
        infoObj["value"] = values[3];
        infoArr.push(infoObj);
      }
    }
    return infoArr;
  }

  saveSurveyAndNote(resp){
    let survey = {
      patDemoId: resp.item._id,
      notes: [],
      data: [],
      date: new Date(),
    };
    let notes = {
      patDemoId: resp.item._id,
      needs: [],
    };
    let self = this;
    let needDataObj = {};
    let surveyDataObj = {};
    this.needMapping.forEach(function (item) {
      needDataObj[item.mapWith] = {
        chaRating: "Not rated",
        status: "Not started",
        notes: [],
      };
      let surverRating = "";
      if (self.surveySubScale.length != 0) {
        let ind = self.patientService.getIndex(
          self.surveySubScale,
          "mapWith",
          item.mapWith
        );
        if (ind != -1) {
          surverRating = self.surveySubScale[ind]["value"];
        }
      }

      surveyDataObj[item.mapWith] = {
        surveyResponse: surverRating,
      };
    });
    survey.data.push(surveyDataObj);
    notes.needs.push(needDataObj);
    this.toastr.success(this.messageService.messages.patDemoSave);
    this.patientService.saveNote(notes).subscribe((response) => {
      if (response) {
        if (response.error === false) {
        } else {
        }
      } else {
      }
    });
    this.patientService.saveSurvey(survey).subscribe((response) => {
      if (response) {
        if (response.error === false) {
        } else {
        }
      } else {
      } 
    });
    this.router.navigate([`patients`]);
  }
  
  getPatientImport(data, resp) {
    this.patientService.getPatientImport(data).subscribe((response) => {
      if (response) {
        if (response.error === false) {
          this.patImportData =
            response.data &&
            response.data.recordset &&
            response.data.recordset.length != 0
              ? response.data.recordset
              : [];
          this.surveySubScale = [];
          if (this.patImportData.length != 0) {
            this.surveySubScale = this.parseInfo(this.patImportData[0].info);
          }
          this.saveSurveyAndNote(resp)
       
        } else {
          this.toastr.error(this.messageService.messages.someIssue);
        }
      } else {
        this.toastr.error(this.messageService.messages.someIssue);
      }
    });
  }

  savePatDemoInfo(patientObj) {
    this.patientService.savePatient(patientObj).subscribe((response) => {
      if (response) {
        if (response.error === false) {
          let obj = {};
          obj["fname"] = patientObj["fname"];
          obj["lname"] = patientObj["lname"];
          let month = new Date(patientObj["dob"]).getUTCMonth() + 1;
          let day = new Date(patientObj["dob"]).getUTCDate();
          let year = new Date(patientObj["dob"]).getUTCFullYear();
          let finalDate = month + "/" + day + "/" + year;
          obj["dob"] = finalDate;
         // this.getPatientImport(obj, response);
         this.saveSurveyAndNote(response);
        } else {
          this.toastr.error(this.messageService.messages.someIssue);
        }
      } else {
        this.toastr.error(this.messageService.messages.someIssue);
      }
    });
  }

  submitPatDemoForm() {
    const controls = this.patDemoForm.controls;

    if (this.patDemoForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    let dob = null;
    if (controls.dob.value) {
      controls.dob.value.month = controls.dob.value.month - 1;
      dob = new Date(
        controls.dob.value.year,
        controls.dob.value.month,
        controls.dob.value.day
      );
    }

    const patDemoFormObject = {
      fname: controls.fname.value,
      lname: controls.lname.value,
      dob: dob,
      gender: controls.gender.value,
      nonPatient: controls.nonPatient.value,
      outReach: controls.outReach.value,
      phone: controls.phone.value,
      chaName: controls.chaName.value,
      language: controls.language.value,
      sms: controls.sms.value,
      insuranceAndOtherServices: controls.insuranceAndOtherServices.value,
      provider: {
        name: controls.provider["controls"].name.value,
        email: controls.provider["controls"].email.value,
        phone: controls.provider["controls"].phone.value,
      },
      practiceKeyContact: {
        contact: controls.practiceKeyContact["controls"].contact.value,
        email: controls.practiceKeyContact["controls"].email.value,
        phone: controls.practiceKeyContact["controls"].phone.value,
      },
      practice: {
        name: controls.practice["controls"].name.value,
        email: controls.practice["controls"].email.value,
        phone: controls.practice["controls"].phone.value,
      },
      caregiver: {
        name: controls.caregiver["controls"].name.value,
        phone: controls.caregiver["controls"].phone.value,
      },
      overallStatus: controls.overallStatus.value,
      mrn: controls.mrn.value,
      tasks: this.task,
    };

    if (this.mode == "edit") {
      patDemoFormObject["overallStatus"] = this.overAllStatusModel;
      this.updatePatDemoInfo(patDemoFormObject);
    } else {
      this.savePatDemoInfo(patDemoFormObject);
    }
  }

  deleteNote(map, index) {
    let uid = this.noteData.needs[0][map].notes[index].uid;
    let ind = this.patientService.getIndex(this.allNotes, "uid", uid);
    if (ind != -1) {
      this.allNotes.splice(ind, 1);
    }

    this.noteData.needs[0][map].notes.splice(index, 1);
    let data = {};
    data["needs"] = this.noteData.needs;
    // let data = { mappedWith: map };
    // data["note"] = this.noteData.needs[0][map].notes[index];
    // data["type"] = "delete";
    this.updateNotes(data);
  }

  onNoteDateSelect(event, map, index) {
    event.month = event.month - 1;
    let date = new Date(
      event.year,
      event.month,
      event.day,
      new Date().getHours(),
      new Date().getMinutes(),
      new Date().getSeconds(),
      new Date().getMilliseconds()
    );
    let uid = this.noteData.needs[0][map].notes[index].uid;
    let ind = this.patientService.getIndex(this.allNotes, "uid", uid);
    if (ind != -1) {
      this.allNotes[ind].date = date;
    }
    this.noteData.needs[0][map].notes[index].date = date;
    let data = {};
    data["needs"] = this.noteData.needs;
    // let data = { mappedWith: map };
    // data["note"] = this.noteData.needs[0][map].notes[index];
    // data["type"] = "date";
    this.updateNotes(data);
  }

  saveSurveyNote() {
    if (this.surveyNoteText != undefined && this.surveyNoteText != null) {
      this.surveyNoteText = this.surveyNoteText.trim();
    }
    if (this.surveyNoteText) {
      let uid = this.patientService.makeid();
      let noteObj = {
        date: new Date(),
        note: this.surveyNoteText,
        createdBy: this.userName,
        uid: uid,
      };
      let data = {};
      this.currentSurvey.notes.unshift(noteObj);
      data["notes"] = this.currentSurvey.notes;
      // data["note"] = noteObj;
      this.updateSurvey(data);
      noteObj["for"] = "General Notes";
      this.allNotes.unshift(noteObj);
    }
  }

  deleteSurveyNote(index) {
    let uid = this.currentSurvey.notes[index].uid;
    let ind = this.patientService.getIndex(this.allNotes, "uid", uid);
    if (ind != -1) {
      this.allNotes.splice(ind, 1);
    }
    this.currentSurvey.notes.splice(index, 1);
    let data = {};
    data["notes"] = this.currentSurvey.notes;
    // data["note"] = this.currentSurvey.notes[index];
    // data["type"] = "delete";
    this.updateSurvey(data);
  }

  onSurveyDateSelect(event, index) {
    event.month = event.month - 1;
    let date = new Date(
      event.year,
      event.month,
      event.day,
      new Date().getHours(),
      new Date().getMinutes(),
      new Date().getSeconds(),
      new Date().getMilliseconds()
    );
    let uid = this.currentSurvey.notes[index].uid;
    let ind = this.patientService.getIndex(this.allNotes, "uid", uid);
    if (ind != -1) {
      this.allNotes[ind].date = date;
    }
    this.currentSurvey.notes[index].date = date;
    let data = {};
    data["notes"] = this.currentSurvey.notes;
    // data["note"] = this.currentSurvey.notes[index];
    // data["type"] = "date";
    this.updateSurvey(data);
  }

  closePopOver() {
    this.noteText = "";
    this.surveyNoteText = "";
  }

  openPopOver() {
    this.noteText = "";
    this.surveyNoteText = "";
  }

  openResPopOver(text) {
    this.respText = text;
  }
  openNeedPopOver(text) {
    this.needText = text;
  }

  saveResp(map, popover) {
    if (this.respText != undefined && this.respText != null) {
      this.respText = this.respText.trim();
    }
    if (this.respText) {
      if(!this.currentSurvey.data[0][map].surveyResponse){
        this.currentSurvey.data[0][map].status = 'inactive';
      }
      this.currentSurvey.data[0][map].surveyResponse = this.respText;
      var data = {};
      data["data"] = this.currentSurvey.data;
      popover.close();
      this.updateSurvey(data);
    } else {
      this.toastr.error(this.messageService.messages.emptyResp);
    }
  }

  saveNeed(map, popover) {
    if (this.needText != undefined && this.needText != null) {
      this.needText = this.needText.trim();
    }
    if (this.needText) {
      this.currentSurvey.data[0][map]["name"] = this.needText;
      var data = {};
      data["data"] = this.currentSurvey.data;
      popover.close();
      this.updateSurvey(data);
    } else {
      this.toastr.error(this.messageService.messages.emptyNeed);
    }
  }

  onCheckChange(event, name) {
    /* Selected */
    if (event.target.checked) {
      this.task.push(name);
    } else {
      /* unselected */
      const index = this.task.indexOf(name);
      if (index > -1) {
        this.task.splice(index, 1);
      }
    }
    if (this.mode == "edit") {
      let data = {};
      data["tasks"] = this.task;
      this.updatePatDemoInfo(data);
    }
  }
  updateSurvey(data) {
    this.patientService
      .updateSurvey(this.currentSurvey._id, data)
      .subscribe((response) => {
        if (response) {
          if (response.error === false) {
            this.toastr.success(this.messageService.messages.surveyUpdate);
            this.surveyNoteText = "";
          } else {
            this.toastr.error(this.messageService.messages.someIssue);
          }
        } else {
          this.toastr.error(this.messageService.messages.someIssue);
        }
      });
  }

  updateNotes(data) {
    this.patientService
      .updateNote(this.noteData._id, data)
      .subscribe((response) => {
        if (response) {
          if (response.error === false) {
            this.toastr.success(this.messageService.messages.surveyUpdate);
            this.noteText = "";
          } else {
            this.toastr.error(this.messageService.messages.someIssue);
          }
        } else {
          this.toastr.error(this.messageService.messages.someIssue);
        }
      });
  }

  setSurvey(index, title) {
    this.currentSurvey = this.surveyData[index];
    this.surveyTitle = title;
  }

  getNotes() {
    this.patientService.getNotes(this.patDemoId).subscribe((response) => {
      if (response) {
        if (response.error === false) {
          if (response.data && response.data.length != 0) {
            this.noteData = response.data[0];
            let needs = this.noteData.needs[0];
            let self = this;
            Object.keys(needs).forEach(function (key) {
              if (needs[key].notes && needs[key].notes.length != 0) {
                needs[key].notes = needs[key].notes.sort((val1, val2) => {
                  return <any>new Date(val2.date) - <any>new Date(val1.date);
                });
                const newArr = needs[key].notes.map((v) => ({
                  ...v,
                  for: key
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    }),
                }));
                self.allNotes = self.allNotes.concat(newArr);
              }
            });

            this.allNotes = this.allNotes.sort((val1, val2) => {
              return <any>new Date(val2.date) - <any>new Date(val1.date);
            });
          }
          this.showSpinner = false;
          setTimeout(() => {
            window.postMessage({ action: "pageLoaded" }, "*");
          }, 100);
        } else {
          this.showSpinner = false;
        }
      } else {
        this.showSpinner = false;
      }
    });
  }

  getSurvey() {
    this.patientService.getSurvey(this.patDemoId).subscribe((response) => {
      if (response) {
        if (response.error === false) {
          if (response.data && response.data.length != 0) {
            this.surveyData = response.data;
            this.currentSurvey = this.surveyData[0];
            this.surveyTitle = this.surveyData[0].date;
            let note = this.currentSurvey.notes;
            // let self = this;
            // this.surveyData.forEach(function (item) {
            //   if (item.notes && item.notes.length != 0) {
            //     self.allNotes = self.allNotes.concat(item.notes);
            //   }
            // });

            let newArr = [];
            if (note && note.length != 0) {
              this.currentSurvey.notes = this.currentSurvey.notes.sort(
                (val1, val2) => {
                  return <any>new Date(val2.date) - <any>new Date(val1.date);
                }
              );
              newArr = note.map((v) => ({ ...v, for: "General Notes" }));
            }
            this.allNotes = this.allNotes.concat(newArr);

            this.allNotes = this.allNotes.sort((val1, val2) => {
              return <any>new Date(val2.date) - <any>new Date(val1.date);
            });
          }
        }
      } else {
      }
    });
  }
}
