import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {AuthService} from "../../../shared/service/auth/auth.service";
import { ToastrService } from "ngx-toastr";
import { MessageService } from "../../service/message/message.service";

@Component({
  selector: "app-base",
  templateUrl: "./base.component.html",
  styleUrls: ["./base.component.scss"],
})
export class BaseComponent implements OnInit {
  userName:any;
  userType:any;
  constructor(private router: Router, private route: ActivatedRoute,private authService:AuthService, private toastr: ToastrService,private messageService: MessageService) {
    this.userName = "";
    this.userType = "user";
  }

  ngAfterViewInit() {
    //We loading the player script on after view is loaded
    import("../../../../assets/scripts.bundle.js");
  }

  ngOnInit(): void {
    let userInfo = this.authService.getUserInfo();
    this.userName = userInfo['name'] ? userInfo['name'] :"";
    this.userType = userInfo['type'] ? userInfo['type'] :"user";
  }

  goToPatient() {
    this.router.navigate([`patients`]);
  }
  goToUser() {
    this.router.navigate([`users`]);
  }
  logout(){
    this.authService
    .logout()
    .subscribe((response) => {
      if (response) {
        localStorage.removeItem("userInfo");
        this.toastr.success(this.messageService.messages.logOut);
        this.router.navigate([`login`]);
      } else {
        this.toastr.error(this.messageService.messages.someIssue);
      }
    });  

  }
}
